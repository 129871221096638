<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <div class="w-75 mx-auto">
    <b-card>
      <b-card-body>
        <h1>{{ $t('Connect your Facebook') }}</h1>
        <div class="text-muted">
          {{ $t('Proceed to connect your Facebook Account to be able to take action') }}
        </div>
      </b-card-body>
    </b-card>
    <b-card v-if="!connected">
      <b-card-header>
        <h3 class="w-100">
          {{ $t('Make connection with Fanpage') }}
        </h3>
        <div class="text-muted">
          {{ $t('Connect with Fanpage by clicking Connect now') }}
        </div>
      </b-card-header>
      <b-card-body>
        <b-button
          variant="primary"
          class="btn-icon rounded"
          @click="reconnectFB()"
        >
          {{ $t('Connect now') }}
        </b-button>
      </b-card-body>
    </b-card>
    <b-card v-else>
      <b-card-header>
        <h3 class="w-100">
          {{ $t('Successful connection') }}
        </h3>
        <div class="text-muted">
          {{ $t('You have successfully connected to your Facebook account') }}
        </div>
      </b-card-header>
      <b-card-body>
        <!-- <router-link
          to="fanpage-list"
          class="mr-4"
        >
          {{ $t('Go to Fanpage chat') }}
        </router-link> -->
        <router-link
          to="channel/facebook-live"
        >
          {{ $t('Go to Facebook Live') }}
        </router-link>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard,
  BCardHeader,
  BCardBody,
  // BImgLazy,
  // BAspect,
  // BLink,
  BButton,
  // BAvatar,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    // BImgLazy,
    // BAspect,
    // BLink,
    BButton,
    // BAvatar,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      token: null,
      connected: false,
      listFanpage: [
        // {
        //   access_token: 'EAAdIOboJs5IBALH8oKD9jI0ZBhWr8wA1sqYNZBUBXiH0xeXfmr58szRKrYB70u8AYcOTNREZCrHdhyf83fXuSR5hMS6h80sFlQUJZARb1DujBRVxZCiSGDO9DxIkEMUZBG6gBTKT4shkuBdyGBJGLlwD8HpklJkzK6tTdiUCDkEch3Qk8luh3aFS3hqWiqR4JUeRMPDcHfZAxg8YUYEmZBGr',
        //   id: 101681312382465,
        //   name: 'Page test 22',
        //   category: 'Clothing store',
        // },
        // {
        //   access_token: 'EAAdIOboJs5IBAKWOBWO0a7KLKKHqSaZAy7JNq0xPMp1FgRx650JWu6y39MMtRrPRSnXozYSZBCFtSP37hIbTrtZC4A3VlXP5q4lqFMn0UZBlhdCyRr0nT7idM99oqsrCta4YvhCEGnbUN4jUflEe4eQ6DfgSH09RsGUikMWLBX2Uig8NbYbwfAnApWWVu3h22xidAKOc3732XX3q089m',
        //   id: 103537912189508,
        //   name: 'Page test',
        //   category: 'Clothing store',
        // },
      ],
    }
  },
  mounted() {
    if (this.$route.query.auth === 'expired') {
      this.handlerUpdateToken('token', 2)
    }
  },
  created() {
    this.loadSiteInfo()
  },
  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            let url = null
            if (res.data.data.token_live_facebook !== '' && res.data.data.token_live_facebook !== null && res.data.data.token_live_facebook !== 'undefined') {
              this.connected = true
              if (JSON.parse(res.data.data.token_live_facebook).token) {
                this.token = JSON.parse(res.data.data.token_live_facebook).token
              }
            } else if (this.$router.currentRoute.hash !== '') {
              const urlHash = this.$router.currentRoute.hash.split('&')
              url = urlHash[0].split('=')
              await this.getLongToken(url[1])
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getLongToken(shtoken) {
      try {
        const shorttoken = shtoken
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/facebook/get_long_access_token?site_id=${JSON.parse(localStorage.getItem('siteID')).id}&access_token=${shorttoken}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const temp = res.data.data.access_token
            this.handlerUpdateToken(temp, 1)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdateToken(token, id) {
      try {
        // const params = {}
        const params = {
          token_live_facebook: {
          },
        }
        if (id === 1) {
          // params.token_live_facebook.token = token
          params.token_live_facebook = `{"token":"${token}"}`
        }
        if (id === 2) {
          // params.token_live_facebook.token = token
          params.token_live_facebook = ''
          this.connected = false
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            if (id === 1) { this.connected = true }
            if (id === 2) { this.connected = false }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Great',
                icon: 'AlertOctagonIcon',
                variant: 'success',
                text: 'Successful!',
              },
            })
          } else if (res.data.error.code === 190) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: 'Session has expired',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async reconnectFB() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/setting-fb`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            window.location.href = res.data.data.url
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
